import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LoaderIcon } from 'assets/images/loader.svg';

import styles from './Loader.module.scss';

const Loader = props => {
  return (
    <div className={styles.loader}>
      <LoaderIcon />
    </div>
  );
};

Loader.propTypes = {

};

export default Loader;
