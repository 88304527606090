import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import 'typeface-roboto';
import 'assets/scss/index.scss';
// import 'utils/i18n';

import Root from './modules/Root';

ReactDOM.render(
  <Root />,
  document.getElementById('root'),
);
