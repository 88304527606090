function getCurrentLang(location) {
  const pathnameArray = location.pathname.split('/');
  const langLevel = pathnameArray[1].toUpperCase();

  switch (langLevel) {
    case 'AZ':
    case 'RU':
    case 'EN': return langLevel
    default: return 'EN';
  }
}

export default getCurrentLang;
