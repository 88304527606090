import zamanLogoAz from '../../assets/images/zaman-copyright-az.svg';
import zamanLogoEn from '../../assets/images/zaman-copyright-en.svg';

export const FOOTER_LOGO = (currentLang) => {
  switch (currentLang) {
    case 'RU': return zamanLogoEn;
    case 'AZ': return zamanLogoAz;
    case 'EN': return zamanLogoEn;
    default: return zamanLogoEn;
  }
}

export const FACEBOOK_SHARE = "https://www.facebook.com/kaskobutik/";
