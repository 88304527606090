import React from 'react';
// import JssProvider from 'react-jss';
// import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';

import { Router } from 'react-router-dom';
import history from '../../history';
import Content from './Content';

// const generateClassName = createGenerateClassName();
const jss = create({
  // ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const Root = () => (
    // <JssProvider jss={jss}>
        <Router history={history}>
          <Content />
        </Router>
    // </JssProvider>
);

export default Root;
