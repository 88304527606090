import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// import { ChildrenType } from 'types';
import styles from './Background.module.scss';

const Background = ({ className, children }) => (
  <main className={cx(styles.main, className)}>
    {children}
  </main>
);

Background.defaultProps = {
  className: '',
  children: null,
};

Background.propTypes = {
  // children: ChildrenType,
  className: PropTypes.string,
};

export default Background;
