import icon1 from 'assets/images/hamidantez/1.svg';
import icon2 from 'assets/images/hamidantez/2.svg';
import icon3 from 'assets/images/hamidantez/3.svg';
import icon4 from 'assets/images/hamidantez/4.svg';
import icon5 from 'assets/images/hamidantez/5.svg';
import icon6 from 'assets/images/hamidantez/6.svg';
import icon7 from 'assets/images/hamidantez/7.svg';

import icon1m from 'assets/images/hamidantez/icon-1.svg';
import icon2m from 'assets/images/hamidantez/icon-2.svg';
import icon3m from 'assets/images/hamidantez/icon-3.svg';
import icon4m from 'assets/images/hamidantez/icon-4.svg';
import icon5m from 'assets/images/hamidantez/icon-5.svg';
import icon6m from 'assets/images/hamidantez/icon-6.svg';
import icon7m from 'assets/images/hamidantez/7.svg';

export const ICONS_HAMIDANTEZ = [icon1, icon2, icon3, icon4, icon5, icon6, icon7];
export const ICONS_HAMIDANTEZ_MOBILE = [icon1m, icon2m, icon3m, icon4m, icon5m, icon6m, icon7m];
