import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper-coverflow-improve/react';
import SwiperCore, { EffectCoverflow, Controller } from 'swiper-coverflow-improve';

import kFit from 'assets/images/k_fit.svg';
import plusIcon from 'assets/images/plus_icon.svg';
import flipSound from 'assets/sounds/flip-card.wav';
import vibraionSound from 'assets/sounds/vibration.wav';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Slider from './Slider';
import CardKMatic from './CardKMatic';
import AnimatedSliderBlock from "./AnimatedSliderBlock";

import styles from './LimitedSize.module.scss';
import SliderContent from "./SliderContent";

const sortCard = ['sUrl', 'mUrl', 'lUrl', 'xlUrl', 'xxlUrl'];
const soundFlip = new Audio(flipSound);
const soundVibration = new Audio(vibraionSound);

SwiperCore.use([EffectCoverflow, Controller]);

const LimitedSize = ({ content, screen }) => {
  const [isViewSlider, setIsViewSlider] = useState(false);
  const [activeDesc, setActiveDesc] = useState(null);
  const switchCard = () => {
    setActiveDesc((prevActiveDesc) => !prevActiveDesc);
    soundFlip.play();
  };
  const innerText = (value) => ({ __html: value });

  return (
    <div className={cx(styles.wrapper)}>
      <div className={cx(styles.inner)}>
        <div
          className={cx({ [styles.slideText]: isViewSlider, [styles.backSlideText]: !isViewSlider })}
        >
          <h2>{content.kFit.slogan}</h2>
        </div>
        <div
          className={cx(styles.main, {
            [styles.slideText]: isViewSlider,
            [styles.backSlideText]: !isViewSlider,
          })}
        >
          <div className={styles.logo}>
            <img src={kFit} alt="kfit" />
          </div>
          <div className={styles.textBlock}>
            <h1>{content.kFit.kFitTitle}</h1>
            <p dangerouslySetInnerHTML={innerText(content.kFit.kFitDescription)}></p>
          </div>
        </div>
        <div className={styles.sizeBlock}>
          {sortCard.map((key) => (
            <CardKMatic
              key={key}
              images={[content.kFit.frontFaces[`${key}`], content.kFit.backFaces[`${key}`]]}
              activeDesc={activeDesc}
            />
          ))}
        </div>
        <div
          className={cx(styles.btbBlock, {
            [styles.slideText]: isViewSlider,
            [styles.backSlideText]: !isViewSlider,
          })}
        >
          <div className={styles.btnWrapper}>
            <button type="button" className={styles.btn} onClick={switchCard}>
              {content.common.compareButton}
            </button>
          </div>
          {/* <button className={styles.plusBtn} onClick={() => setIsViewSlider(true)} type="button"> */}
          {/*  <img src={plusIcon} alt="" /> */}
          {/* </button> */}
        </div>
        <AnimatedSliderBlock
          isViewSlider={isViewSlider}
          setIsViewSlider={setIsViewSlider}
          screen={screen}
        />
        <CSSTransition
          in={isViewSlider}
          timeout={600}
          unmountOnExit
          classNames={{
            enter: styles.sliderEnter,
            enterActive: styles.sliderEnterActive,
            enterDone: styles.sliderEnterDone,
            exit: styles.sliderExit,
            exitActive: styles.sliderExitActive,
            exitDone: styles.sliderEnter,
          }}
        >
          <SliderContent
            isViewSlider={isViewSlider}
            setIsViewSlider={setIsViewSlider}
            content={content}
            screen={screen}
          />
        </CSSTransition>
      </div>
    </div>
  );
};

LimitedSize.propTypes = {
  content: PropTypes.shape({
    kFit: PropTypes.shape({
      slogan: PropTypes.string,
      kFitDescription: PropTypes.string,
      kFitTitle: PropTypes.string,
    }),
    common: PropTypes.shape({
      compareButton: PropTypes.string,
    }),
  }).isRequired,
  screen: PropTypes.string.isRequired,
};

export default LimitedSize;
