import React, { useState } from 'react';
import minusIcon from 'assets/images/minus_icon.svg';
import shareIcon from 'assets/images/share_icon.svg';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './FullKaskoSize.module.scss';

const Slider = (props) => {
  const { setisViewSlider } = props;
  const [activeSlide, setActiveSlide] = useState('1');

  return (
    <div className={styles.shadowWrapperr}>
      <div className={styles.slider}>
        <svg width="0" height="0">
          <clipPath id="myClip">
            <path className={styles.svgSquare} id="path" />
          </clipPath>
        </svg>
        <button className={styles.minusBtn} onClick={() => setisViewSlider(false)} type="button">
          <img src={minusIcon} alt="" />
        </button>
        <a target="_blank" href="https://www.facebook.com/kaskobutik/" className={styles.shareBtn}>
          <img src={shareIcon} alt="share" />
        </a>
        <h2>Акция kMatik</h2>
        <div className={styles.pointBlock}>
          {/* test data */}
          {[1, 2, 3].map((number) => (
            <div
              key={number}
              className={cx(styles.point, { [styles.active]: number === activeSlide })}
              onClick={() => setActiveSlide(number)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

Slider.propTypes = {
  setisViewSlider: PropTypes.func.isRequired,
};

export default Slider;
