import React from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import Background from 'components/Background';
import { FullKaskoSize } from 'modules/FullKaskoSizeInsurance';
import Questionnaire from 'modules/Questionnaire';
import MaintenancePage from 'modules/MaintenancePage';
import App from 'modules/App';
import Payment from 'modules/Payment/Payment';
import PaymentSuccess from 'modules/Payment/PaymentSuccess';
import PaymentFail from 'modules/Payment/PaymentFail';
import SurveyPage from 'modules/SurveyPage';
import styles from './Content.module.scss';

const Content = (props) => {

  return (
    <div className={styles.wrapper}>
      <Background>
        <Switch>
          <Route exact path="/*" component={MaintenancePage} />
          <Route exact path="/" component={App} />
          <Route exact path="/maintenance" component={MaintenancePage} />
          <Route exact path="/fullKasko" component={FullKaskoSize} />
          <Route exact path="/quest" component={Questionnaire} />
          <Route exact path="/kasko/:id" component={Payment} />
          <Route exact path="/paymentsuccess" component={PaymentSuccess} />
          <Route exact path="/goldenpaymentsuccess" component={PaymentSuccess} />
          <Route exact path="/paymenterror" component={PaymentFail} />
          <Route exact path="/goldenpaymenterror" component={PaymentFail} />
          <Route exact path="/goldenpaymentbacktoapp" component={() => <div />} />
          <Route exact path="/claimsurvey" component={SurveyPage} />
          <Route exact path="/:lang([a-z]{2})/claimsurvey" component={SurveyPage} />
          <Route path="/help" render={() => <div>help</div>} />
          <Redirect to="/" />
        </Switch>
      </Background>
    </div>
  )
}


export default withRouter(Content);
