import logoTitleRu from 'assets/svg/title-ru.svg';
import logoTitleEn from 'assets/svg/title-en.svg';
import logoTitleAz from 'assets/svg/title-az.svg';

export const HEADER_TAG_LINE = (currentLang) => {
  switch (currentLang) {
    case 'RU': return logoTitleRu;
    case 'AZ': return logoTitleAz;
    case 'EN': return logoTitleEn;
    default: return logoTitleEn;
  }
}
