import React, { useState } from 'react';
import kMatik from 'assets/images/k-matik.svg';
import plusIcon from 'assets/images/plus_icon.svg';
import flipSound from 'assets/sounds/flip-card.wav';
import vibraionSound from 'assets/sounds/vibration.wav';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import CardKFit from './CardKFit';
import Slider from './Slider';
import styles from './FullKaskoSize.module.scss';

const sordCard = ['k500', 'k1000', 'k2000', 'k2000Plus'];

const soundFlip = new Audio(flipSound);
const soundVibration = new Audio(vibraionSound);

const FullKaskoSize = ({ content, isMobile }) => {
  const [isViewSlider, setIsViewSlider] = useState(false);
  const [activeDesc, setActiveDesc] = useState(false);
  const switchCard = () => {
    setActiveDesc((prevActiveDesc) => !prevActiveDesc);
    soundVibration.play();
    setTimeout(() => soundFlip.play(), 500);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={cx(styles.main, {
          [styles.slideText]: isViewSlider,
          [styles.backSlideText]: !isViewSlider,
        })}
      >
        <div className={styles.headersWrapper}>
          <div className={styles.headers}>
            <h2>{content.kMatik.slogan}</h2>
            <h1>{content.kMatik.kMatikTitle}</h1>
          </div>
        </div>
        <div className={styles.textBlock}>
          <p>{content.kMatik.kMatikDescription}</p>
          <div className={styles.logo}>
            <img src={kMatik} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.sizeBlock}>
        {sordCard.map((key) => (
          <CardKFit
            key={key}
            images={[content.kMatik.frontFaces[`${key}`], content.kMatik.backFaces[`${key}`]]}
            activeDesc={activeDesc}
            isMobile={isMobile}
          />
        ))}
        {!isViewSlider && content.kMatik.campaign.site && (
          <button className={styles.plusBtn} onClick={() => setIsViewSlider(true)} type="button">
            <img src={plusIcon} alt="" />
          </button>
        )}
      </div>
      <div
        className={cx(styles.footer, {
          [styles.slideText]: isViewSlider,
          [styles.backSlideText]: !isViewSlider,
        })}
      >
        <button type="button" className={styles.btn} onClick={switchCard}>
          {content.common.compareButton}
        </button>
      </div>
      <CSSTransition
        in={isViewSlider}
        timeout={600}
        unmountOnExit
        classNames={{
          enter: styles.sliderEnter,
          enterActive: styles.sliderEnterActive,
          exit: styles.sliderExit,
          exitActive: styles.sliderExitActive,
          exitDone: styles.sliderEnter,
        }}
      >
        <Slider setisViewSlider={setIsViewSlider} />
      </CSSTransition>
    </div>
  );
};

FullKaskoSize.propTypes = {
  content: PropTypes.shape({
    kMatik: PropTypes.shape({
      slogan: PropTypes.string,
      kMatikDescription: PropTypes.string,
      kMatikTitle: PropTypes.string,
    }),
    common: PropTypes.shape({
      compareButton: PropTypes.string,
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default FullKaskoSize;
