import React, { useEffect, useState } from 'react';
import {
  Link, withRouter,
} from 'react-router-dom';
import cx from 'classnames';
import logo from 'assets/images/logo.svg';
import flag from 'assets/images/payment/flag_of_Azerbaijan.png';
import sIcon from 'assets/images/payment/s-logo.png';
import mIcon from 'assets/images/payment/m-logo.png';
import lIcon from 'assets/images/payment/l-logo.png';
import xlIcon from 'assets/images/payment/xl-logo.png';
import xxlIcon from 'assets/images/payment/xxl-logo.png';
import K500 from 'assets/images/payment/k500-logo.png';
import K1000 from 'assets/images/payment/k1000-logo.png';
import K2000 from 'assets/images/payment/k2000-logo.png';
import K2000PLUS from 'assets/images/payment/k2000plus-logo.png';
import manat from 'assets/images/payment/manat.png';
import PropTypes from 'prop-types';

import * as requests from 'requests';
import styles from './Payment.module.scss';

const translate = {
  title: 'Выбери свой размер!',
  payment: 'Оплата',
};

const initialState = {
  data: {
    plateNumber: '       ',
  },
};

const iconComponents = {
  S: sIcon,
  M: mIcon,
  L: lIcon,
  XL: xlIcon,
  XXL: xxlIcon,
  K500,
  K1000,
  K2000,
  K2000PLUS,
};

const Payment = (props) => {
  const [data, setData] = useState(initialState);
  const { match, history } = props;
  const fetchData = () => {
    requests
      .getPaymentByPolicyNumber(match.params.id)
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const pay = async () => {
    const params = {
      amount: data.amount * 100,
      policyNumber: match.params.id,
      time: new Date() * 1,
    };

    try {
      const response = await requests.payPashaBank(params);
      window.location.href = response;
    } catch (error) {
      history.push('./');
      console.log(error);
    }
  };
  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt="logo" />
      <h3>{translate.title}</h3>

      <div className={styles.main}>
        <h1>{translate.payment}</h1>
        {data.basicOptionName && (
          <img className={styles.sizeIcon} src={iconComponents[data.basicOptionName]} alt="plan" />
        )}
        <p className={styles.text}>Номер автомобиля</p>
        <div className={styles.vehicleNumber}>
          <div className={styles.flag}>
            <img src={flag} alt="AZ" />
            <span>AZ</span>
          </div>

          <div className={styles.mask}>
            <input
              type="text"
              maxLength="1"
              value={data.plateNumber ? data.plateNumber[0] : ''}
              name="0"
              readOnly
            />
            <input
              type="text"
              maxLength="1"
              value={data.plateNumber ? data.plateNumber[1] : ''}
              name="1"
              readOnly
            />
            <span>-</span>
            <input
              type="text"
              maxLength="1"
              value={data.plateNumber ? data.plateNumber[2] : ''}
              name="2"
              readOnly
            />
            <input
              type="text"
              maxLength="1"
              value={data.plateNumber ? data.plateNumber[3] : ''}
              name="3"
              readOnly
            />
            <span>-</span>
            <input
              type="text"
              maxLength="1"
              value={data.plateNumber ? data.plateNumber[4] : ''}
              name="4"
              readOnly
            />
            <input
              type="text"
              maxLength="1"
              value={data.plateNumber ? data.plateNumber[5] : ''}
              name="5"
              readOnly
            />
            <input
              type="text"
              maxLength="1"
              value={data.plateNumber ? data.plateNumber[6] : ''}
              name="6"
              readOnly
            />
          </div>
        </div>

        {/* <table className={cx({[styles.kMatic] : data.basicOptionName.charAt(0) === 'K', [styles.kFit]: data.basicOptionName.charAt(0) !== "K"})}> */}
        <table
          className={cx({
            [styles.paymentTable]: true,
            [styles.kMatik]:
              typeof data.basicOptionName === 'string' && data.basicOptionName.charAt(0) === 'K',
            [styles.kFit]:
              typeof data.basicOptionName === 'string' && data.basicOptionName.charAt(0) !== 'K',
          })}
        >
          <tbody>
            <tr>
              <td>Номер полиса</td>

              <td colSpan="2">{match.params.id}</td>
            </tr>
            <tr>
              <td>Цена</td>
              <td></td>
              <td>{data.total}</td>
            </tr>
            <tr>
              <td>Оплачено</td>
              <td></td>
              <td>{data.payedAmount}</td>
            </tr>
            <tr>
              <td>Долг</td>
              <td></td>
              <td>{data.debt}</td>
            </tr>
            <tr>
              <td>Подлежит к оплате</td>
              <td></td>
              <td>{data.amount}</td>
            </tr>
          </tbody>
        </table>

        <button
          type="button"
          className={styles.btn}
          onClick={pay}
          disabled={!data.amount || data.amount === 0}
        >
          оплатить
        </button>
      </div>
    </div>
  );
};

Payment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Payment);
