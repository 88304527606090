import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import styles from './Questionnaire.module.scss';
import like from '../../assets/images/questionnaire/like.svg';
import dislike from '../../assets/images/questionnaire/dislike.svg';
import activeLike from '../../assets/images/questionnaire/active-like.svg';
import activeDislike from '../../assets/images/questionnaire/active-dislike.svg';
import shortLogo from '../../assets/images/questionnaire/short-logo.svg';
import gPlay from '../../assets/images/questionnaire/google-play.svg';
import appStore from '../../assets/images/questionnaire/app-store.svg';
import qrCode from '../../assets/svg/qr-code.svg';
import number from '../../assets/images/questionnaire/phone-number.svg';
import facebook from '../../assets/images/questionnaire/facebook.svg';
import linkedin from '../../assets/images/questionnaire/linkedin.svg';
import instagram from '../../assets/images/questionnaire/instagram.svg';
import youtube from '../../assets/images/questionnaire/youtube.svg';
import lloyds from '../../assets/images/questionnaire/lloyds.svg';

import { FOOTER_LOGO } from '../Questionnaire/consts';

import styles from './Information.module.scss';

const score = {
  like,
  dislike,
  activeLike,
  activeDislike,
};

const InformationMobile = ({ content, currentLang }) => {
  const [actionIcon, setActionIcon] = useState(null);
  const innerText = (value) => ({ __html: value });

  const onSubmit = (event) => {
    event.preventDefault();
    console.log(event.target.text.value);
    console.log(event.target.phone.value);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.informationWrapper}>
        <div className={styles.communicate}>
          <div className={styles.store}>
            <h2 dangerouslySetInnerHTML={innerText(content.footer.titleMob)} />
            <div className={styles.linkBlock}>
              <a>
                <img src={shortLogo} alt="short logo" className={styles.kButik} />
              </a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.anotap.kasko&hl=en">
                <img src={gPlay} alt="google paly" className={styles.gPlay} />
              </a>
              <a target="_blank" href="https://apps.apple.com/az/app/kasko-butik/id1435816463">
                <img src={appStore} alt="app store" className={styles.appStore} />
              </a>
            </div>
          </div>
          <div className={styles.contact}>
            <img src={number} alt="number" className={styles.number} />
            <p dangerouslySetInnerHTML={innerText(content.footer.copyright)} />
          </div>
          <div className={styles.copyright}>
            <div className={styles.logoContainer}>
              <span>{content.footer.isAdministered}</span>
              <img className={styles.logoLLoyds} src={lloyds} alt="LLoyd's" />
              <div className={styles.logoWrapper}>
                <a target="_blank" href="https://zamanbroker.az/">
                  <img src={FOOTER_LOGO(currentLang)} alt="zaman logo" />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.social}>
            <a target="_blank" href="https://www.facebook.com/kaskobutik/">
              <img src={facebook} alt="facebook" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/kaskobutik/">
              <img src={linkedin} alt="linkedin" />
            </a>
            <a target="_blank" href="https://www.instagram.com/kasko_butik/?hl=en">
              <img src={instagram} alt="instagram" />
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCe_pP1tuZmGa1EJ4gSvMoYg">
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

InformationMobile.propTypes = {
  content: PropTypes.object.isRequired,
  currentLang: PropTypes.string.isRequired,
}

export default InformationMobile;
