import React, { useEffect } from 'react';
import logo from 'assets/images/logo.svg';
import sadSmileIcon from 'assets/images/payment/sad-smile.svg';
import appStore from 'assets/images/payment/app-store.png';
import googlePlay from 'assets/images/payment/google-play.png';
import phoneNumber from 'assets/images/payment/short-number.png';
import shortLogo from 'assets/images/payment/short-logo.png';
import qrCode from 'assets/images/payment/qr-code.png';
import cx from 'classnames';
import PropTypes from 'prop-types';
import * as requests from 'requests';
import styles from './Payment.module.scss';

// draft translate
const translate = {
  title: 'Выбери свой размер!',
  payment: 'Оплата прошла успешно!',
};

const PaymentFail = (props) => {
  const { location, history } = props;

  useEffect(() => {
    const isGoldenPay = location.pathname.indexOf('goldenpaymenterror') !== -1;
    const queryParamKey = new URLSearchParams(location.search).get('payment_key') || 'no_payment_key';

    if (isGoldenPay) {
      requests.goldenPaymentAudit({ pageStatus: 'error', transactionId: queryParamKey });
    } else {
      requests.paymentAudit({ pageStatus: 'error', transactionId: queryParamKey });
    }
  });

  const queryParam = new URLSearchParams(location.search).get('policyNumber');
  const paymentRedirect = () => {
    history.push(`/kasko/${queryParam}`);
  };

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt="logo" />
      <h3>{translate.title}</h3>

      <div className={cx(styles.main, styles.textCenter)}>
        <h1 className={styles.failTitle}>Произошла ошибка во время оплаты</h1>
        <img className={styles.sadSmileIcon} src={sadSmileIcon} alt="smile" />
        <button type="submit" className={cx(styles.btn, styles.failBtn)} onClick={paymentRedirect}>
          попробуйте снова
        </button>
      </div>
      <div className={styles.linkBlock}>
        <img src={phoneNumber} alt="qr code" />
        <img src={qrCode} alt="qr code" />
        <div className={styles.social}>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.anotap.kasko&hl=en"
          >
            <img src={googlePlay} alt="google play" />
          </a>
          <a target="_blank" href="https://2222.az/">
            <img src={shortLogo} alt="logo" />
          </a>
          <a target="_blank" href="https://apps.apple.com/az/app/kasko-butik/id1435816463">
            <img src={appStore} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

PaymentFail.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default PaymentFail;
