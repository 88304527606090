import React from 'react';
import PropTypes from 'prop-types';

import useWindowDimensions from 'helpers/useWindowDimensions';

import SurveySocial from '../SurveySocial';

import logo from 'assets/images/logo.svg';
import like from 'assets/images/survey/like.png';
import dislike from 'assets/images/survey/dislike.png';

import styles from './SurveyCompleted.module.scss';

const SurveyCompleted = props => {
  const { status, translates } = props;

  const { height, width } = useWindowDimensions();

  const innerText = (value) => ({ __html: value });

  const titleReaction = innerText((status === 'ANSWERED_LIKE' || status === 'ANSWERED_DISLIKE')
    ? translates.survey.titleAlreadyAnswered
    : status === 'LIKE'
      ? translates.survey.titlePositive
      : translates.survey.titleNegative);

  return (
    <div className={styles.container} style={{ height }}>
      <div className={styles.main}>
        <img className={styles.logo} src={logo} alt="logo" />
        <h3 dangerouslySetInnerHTML={innerText(translates.common.chooseYourSize)} />
        <img
          className={styles.reactImage}
          src={status === 'LIKE' || status === 'ANSWERED_LIKE' ? like : dislike}
          alt="Reaction"
        />
        <h1
          className={styles.mainTitle}
          dangerouslySetInnerHTML={titleReaction} />
      </div>
      <div className={styles.surveySocialWrapper}>
        <SurveySocial />
      </div>
    </div>
  );
};

SurveyCompleted.propTypes = {
  status: PropTypes.string.isRequired,
  translates: PropTypes.objectOf().isRequired,
};

export default SurveyCompleted;
