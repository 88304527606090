import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import * as requests from 'requests';
import 'swiper/swiper-bundle.css';
import 'assets/scss/swiper-custom.scss';
import logo from 'assets/images/logo.svg';
import { MOBILE_L, TABLET_L } from 'consts/responsive';
import useWindowDimensions from 'helpers/useWindowDimensions';
import { LimitedSize, LimitedSizeMobile } from '../LimitedSizeInsurance';
import { FullKaskoSize, FullKaskoSizeMobile } from '../FullKaskoSizeInsurance';
import { InsuranceFooter, InsuranceFooterMobile } from '../InsuranceFooter';
import InsuranceHeader from '../InsuranceHeader';
import Questionnaire from '../Questionnaire';
import { Information, InformationMobile } from "../Information";
import { HEADER_TAG_LINE } from '../InsuranceHeader/consts';
import styles from './App.module.scss';

SwiperCore.use([Mousewheel]);

const ContentWrapper = ({ children, isMobile, height }) => (
  isMobile
    ? <div>{children}</div>
    : (
      <Swiper
        direction="vertical"
        spaceBetween={0}
        slidesPerView="auto"
        style={{ height: `${height}px` }}
        mousewheel
        allowTouchMove={isMobile}
        speed={600}
        noSwiping
      >{children}</Swiper>
    )

)
const App = () => {
  const [content, setContent] = useState({});
  const [currentLang, setCurrentLang] = useState('');

  const handleLocalLang = (lang) => {
    localStorage.setItem('language', lang);
  };

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    const language = localStorage.getItem('language') || 'AZ';
    console.log('App -> language', language);
    setCurrentLang(language);
  }, []);

  const [isFetching, setIsFetching] = useState(false);
  console.log('App -> content', content);
  const fetchContent = (lang) => {
    requests
      .getContent(lang)
      .then((response) => {
        setContent(response);
        setIsFetching(true);
        handleLocalLang(lang);
      })
      .catch((err) => {
        console.error(err);
        setIsFetching(false);
      });
  };
  useEffect(() => {
    if (currentLang) fetchContent(currentLang);
  }, [currentLang]);

  const mobile = useMediaQuery({ query: `(max-width: ${MOBILE_L}px)` });
  const tablet = useMediaQuery({ query: `(max-width: ${TABLET_L}px)` });
  const isMobile = mobile || tablet;

  const screen = (() => {
    switch (true) {
      case mobile: return 'mobile';
      case tablet: return 'tablet';
      default: return 'desktop';
    }
  })();

  return (
    <>
      {isFetching && (
        <div className={styles.wrapepr}>
          {isMobile && (
            <div id="header" className={styles.headerFixed}>
              <img src={logo} alt="logo" className={styles.logo}/>
              <img src={HEADER_TAG_LINE(currentLang)} alt="title" className={styles.logoTitle}/>
            </div>
          )}
          <ContentWrapper isMobile={isMobile} height={height}>
            <SwiperSlide style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
              <InsuranceHeader
                content={content}
                setCurrentLang={setCurrentLang}
                currentLang={currentLang}
                isMobile={isMobile}
              />
            </SwiperSlide>
              {screen === 'mobile' || screen === 'tablet'
                ? <LimitedSizeMobile content={content} screen={screen} />
                : <SwiperSlide style={{ display: 'flex', alignItems: 'center' }}><LimitedSize content={content} screen={screen} /></SwiperSlide>
              }
            <SwiperSlide style={{ display: 'flex', alignItems: 'center' }}>
              {screen === 'mobile' || screen === 'tablet'
                ? <FullKaskoSizeMobile content={content} isMobile={isMobile} screen={screen} />
                : <FullKaskoSize content={content} isMobile={isMobile} />
              }
            </SwiperSlide>
            <SwiperSlide>
              {screen === 'mobile' || screen === 'tablet'
                ? <InsuranceFooterMobile
                  content={content}
                  currentLang={currentLang}
                  screen={screen}
                />
                : <InsuranceFooter
                  content={content}
                  currentLang={currentLang}
                />}
            </SwiperSlide>
            <SwiperSlide>
              <Questionnaire
                content={content}
                isMobile={isMobile}
                screen={screen}
                currentLang={currentLang}
              />
            </SwiperSlide>
            <SwiperSlide style={{ height: isMobile ? 'initial' : '700px' }}>
              {screen === 'mobile' || screen === 'tablet'
                ? <InformationMobile content={content} currentLang={currentLang} />
                : <Information content={content} currentLang={currentLang} />
              }
            </SwiperSlide>
          </ContentWrapper>
        </div>
      )}
    </>
  );
};

export default App;
