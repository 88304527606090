import React, { useState, useRef, Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper-coverflow-improve/react';
import SwiperCore, { EffectCoverflow, Controller } from 'swiper-coverflow-improve';
import { CSSTransition } from 'react-transition-group';

import kFit from 'assets/images/k_fit.svg';
import flipSound from 'assets/sounds/flip-card.wav';
import vibraionSound from 'assets/sounds/vibration.wav';
import kFitBg from 'assets/svg/kfit-mobile-bg.svg';
import plusIcon from 'assets/images/plus_icon.svg';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CardKMatic from './CardKMatic';
import AnimatedSliderBlock from './AnimatedSliderBlock';
import SliderContent from './SliderContent';
import styles from './LimitedSize.module.scss';

const sortCard = ['sUrl', 'mUrl', 'lUrl', 'xlUrl', 'xxlUrl'];
const soundFlip = new Audio(flipSound);
const soundVibration = new Audio(vibraionSound);

SwiperCore.use([EffectCoverflow, Controller]);

const LimitedSize = ({ content, screen }) => {
  const [isViewSlider, setIsViewSlider] = useState(false);
  const [activeDesc, setActiveDesc] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const wrapperSwiper = useRef(null);

  const switchCard = () => {
    setActiveDesc((prevActiveDesc) => !prevActiveDesc);
    soundFlip.play();
  };
  const innerText = (value) => ({ __html: value });

  const handleOk = () => {
    wrapperSwiper.current.swiper.slideNext();
  };

  function onSlideChange() {
    const swiper = this;
    setActiveSlide(swiper.activeIndex);
  }

  return (
    <div id="mobileKfitWrapper" className={styles.mobileKfitWrapper}>
      <Swiper ref={wrapperSwiper} style={{ width: '100%' }} resistanceRatio={0}>
        <SwiperSlide>
          <div className={cx(styles.wrapper)}>
            <div className={cx(styles.inner)}>
              <div className={styles.contentWrapper}>
                <div className={cx(styles.main, styles.backSlideText)}>
                  <div className={cx(styles.logo, styles.logoDescription)}>
                    <h2 className={styles.sloganDescription}>{content.kFit.slogan}</h2>
                    <img src={kFit} alt="kfit" />
                  </div>
                  <div className={styles.textBlock}>
                    <h1 dangerouslySetInnerHTML={innerText(content.kFit.kFitTitleMob)}></h1>
                    <p dangerouslySetInnerHTML={innerText(content.kFit.kFitDescription)}></p>
                  </div>
                </div>
                <p
                  className={styles.descriptionText}
                  dangerouslySetInnerHTML={innerText(content.kFit.kFitDescription)}
                />
              </div>
              <div
                className={cx(styles.btbBlock, styles.backSlideText)}
              >
                <div className={styles.btnWrapper}>
                  <button type="button" className={styles.btn} onClick={handleOk}>
                    OK
                  </button>
                </div>
              </div>
            </div>
            <img className={styles.mobileBg} src={kFitBg} alt="kFit" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={`wrapperSlide ${cx(styles.wrapper, styles.sliderWrapper)}`}>
            <div className={cx(styles.inner)}>
              <div
                className={cx(styles.main, styles.backSlideText)}
              >
                <div className={styles.logo}>
                  <h2>{content.kFit.slogan}</h2>
                  <img src={kFit} alt="kfit" />
                </div>
                <div className={styles.textBlock}>
                  <h1 dangerouslySetInnerHTML={innerText(content.kFit.kFitTitleMob)}></h1>
                  <p dangerouslySetInnerHTML={innerText(content.kFit.kFitDescription)}></p>
                </div>
              </div>
              <Swiper
                className={styles.sizeBlock}
                effect="coverflow"
                nested
                watchSlidesProgress
                slidesPerView={1}
                spaceBetween={screen === 'mobile' ? -200 : -525}
                resistanceRatio={0}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: screen === 'mobile' ? 1200 : 2000,
                  modifier: 1,
                  slideShadows: false,
                }}
                onSlideChange={onSlideChange}
              >
                {sortCard.map((key) => (
                  <SwiperSlide key={key}>
                    <CardKMatic
                      key={key}
                      images={[content.kFit.frontFaces[`${key}`], content.kFit.backFaces[`${key}`]]}
                      activeDesc={activeDesc}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className={styles.wrapperPagination}>
                <div className={cx(styles.customPagination, "swiper-pagination swiper-pagination-bullets")}>
                  {/* PHANTOM POINT */}
                  <span className="swiper-pagination-bullet" />
                  {sortCard.map((key, index) => (
                    <span key={key} className={cx("swiper-pagination-bullet", { "swiper-pagination-bullet-active": activeSlide === index })}></span>
                  ))}
                </div>
              </div>
              <div
                className={cx(styles.btbBlock, styles.backSlideText)}
              >
                <div className={styles.btnWrapper}>
                  <button type="button" className={styles.btn} onClick={switchCard}>
                    {content.common.compareButton}
                  </button>
                </div>
                {/* <button className={styles.plusBtn} onClick={() => setIsViewSlider(true)} type="button"> */}
                {/*  <img src={plusIcon} alt="" /> */}
                {/* </button> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {(screen === 'mobile' || screen === 'tablet') && (
        <Fragment>
          <AnimatedSliderBlock
            isViewSlider={isViewSlider}
            setIsViewSlider={setIsViewSlider}
            screen={screen}
          />
          <CSSTransition
            in={isViewSlider}
            timeout={600}
            unmountOnExit
            classNames={{
              enter: styles.sliderEnter,
              enterActive: styles.sliderEnterActive,
              enterDone: styles.sliderEnterDone,
              exit: styles.sliderExit,
              exitActive: styles.sliderExitActive,
              exitDone: styles.sliderEnter,
            }}
          >
            <SliderContent
              isViewSlider={isViewSlider}
              setIsViewSlider={setIsViewSlider}
              content={content}
              screen={screen}
            />
          </CSSTransition>
        </Fragment>
      )}
    </div>
  );
};

LimitedSize.propTypes = {
  content: PropTypes.shape({
    kFit: PropTypes.shape({
      slogan: PropTypes.string,
      kFitDescription: PropTypes.string,
      kFitTitle: PropTypes.string,
    }),
    common: PropTypes.shape({
      compareButton: PropTypes.string,
    }),
  }).isRequired,
  screen: PropTypes.string.isRequired,
};

export default LimitedSize;
