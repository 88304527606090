import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from 'react-responsive'

import { MOBILE_L, TABLET_L } from 'consts/responsive';
import logo from 'assets/images/logo.svg';

import InsuranceHeaderMobileSlider from './InsuranceHeaderMobileSlider';

import { HEADER_TAG_LINE } from './consts';

import styles from './InsuranceHeader.module.scss';

const InsuranceHeader = ({ content, setCurrentLang, currentLang }) => {
  const innerText = (value) => ({ __html: value });

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_L}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${TABLET_L}px)` });

  return (
    <div className={styles.wrapper}>
      {!(isMobile || isTablet) && (
        <div>
          <img src={logo} alt="logo" className={styles.logo}/>
          <img src={HEADER_TAG_LINE(currentLang)} alt="title" className={styles.logoTitle}/>
        </div>
      )}
      {
        isMobile
          ? <InsuranceHeaderMobileSlider content={content} />
          : (
            <div className={styles.titleWrapper}>
              <h2
                className={styles.leftTitle}
                dangerouslySetInnerHTML={innerText(content.common.step1)}
              />
              <h2
                className={styles.rightTitle}
                dangerouslySetInnerHTML={innerText(content.common.step2)}
              />
            </div>
          )
      }
      <div className={styles.languages}>
        {['AZ', 'EN', 'RU'].map((i) => (
          <p
            key={i}
            href=""
            onClick={() => setCurrentLang(i)}
            className={currentLang === i ? styles.currentLang : ''}
          >
            {i}
          </p>
        ))}
      </div>
    </div>
  );
};


InsuranceHeader.propTypes = {
  content: PropTypes.shape({
    common: PropTypes.shape({
      step1: PropTypes.string,
      step2: PropTypes.string,
    }),
  }).isRequired,
  setCurrentLang: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
};

export default InsuranceHeader;
