import React, { useEffect, useState } from 'react';
import logo from 'assets/images/logo.svg';
import { Link, withRouter } from 'react-router-dom';
import manat from 'assets/images/payment/manat.png';
import appStore from 'assets/images/payment/app-store.png';
import googlePlay from 'assets/images/payment/google-play.png';
import phoneNumber from 'assets/images/payment/short-number.png';
import shortLogo from 'assets/images/payment/short-logo.png';
import qrCode from 'assets/images/payment/qr-code.png';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as requests from 'requests';

import styles from './Payment.module.scss';

const translate = {
  title: 'Выбери свой размер!',
  payment: 'Оплата прошла успешно!',
};

const initialState = {
  additionalFee: 0,
  approvalCode: '',
  basicOptionName: '',
  cardName: '',
  cardNumber: '',
  currentPayment: 0,
  fee: 0,
  nextPaymentDate: '',
  policyNumber: '',
  reccPmntExpiry: 0,
  reccPmntId: 0,
  result: '',
  resultCode: '',
  resultPS: '',
  rrn: '',
  secure3d: '',
  total: 0,
};

const PaymentSuccess = (props) => {
  const { location, history } = props;
  const queryParam = location.pathname.indexOf('goldenpaymentsuccess') !== -1
    ? new URLSearchParams(location.search).get('payment_key')
    : new URLSearchParams(location.search).get('trans_id');
  const [paymentData, setPaymentData] = useState(initialState);

  const fetchSuccessData = async () => {
    try {
      const response = await requests.isPaymentSuccess({ transactionId: queryParam });

      if ((response || {}).error === true) {
        history.push(`/paymenterror?policyNumber=${response.policyNumber}`);
      } else {
        setPaymentData({
          ...response,
          paymentNumber: response.policyNumber,
          payedAmount: response.sumAlreadyPaid,
          debt: response.debt,
        });
      }
    } catch (error) {
      console.error(error);
      history.push('/paymenterror');
    }
  };

  const fetchGoldenSuccessData = async () => {
    try {
      const response = await requests.isGoldenPaymentSuccess({ transactionId: queryParam });

      if (response && response.result === false) {
        history.push('/goldenpaymenterror');
        return;
      }
      if ((response && response.insuranceId) === null) {
        history.push('/goldenpaymentbacktoapp');
      } else {
        const responsePay = await requests.goldenMakePay({ insuranceId: response.insuranceId });
        setPaymentData({
          ...responsePay,
          paymentNumber: responsePay.policyNumber,
          payedAmount: responsePay.sumAlreadyPaid,
          debt: responsePay.debt,
        });
      }
    } catch (error) {
      console.error(error);
      history.push('/goldenpaymenterror');
    }
  };

  useEffect(() => {
    const isGoldenPay = location.pathname.indexOf('goldenpaymentsuccess') !== -1;

    if (isGoldenPay) {
      requests.goldenPaymentAudit({ pageStatus: 'success', transactionId: queryParam  || 'no_payment_key' });
      queryParam && fetchGoldenSuccessData();
    } else {
      requests.paymentAudit({ pageStatus: 'success', transactionId: queryParam || 'no_payment_key' });
      queryParam && fetchSuccessData();
    }
  }, []);

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt="logo" />
      <h3>{translate.title}</h3>

      <div className={styles.main}>
        <h1 className={styles.successTitle}>Оплата прошла успешно!</h1>
        <table
          className={cx(styles.tableSuccess, {
            [styles.kMatik]:
              typeof paymentData.basicOptionName === 'string' &&
              paymentData.basicOptionName.charAt(0) === 'K',
            [styles.kFit]:
              typeof paymentData.basicOptionName === 'string' &&
              paymentData.basicOptionName.charAt(0) !== 'K',
          })}
        >
          <tbody>
            <tr>
              <td>Номер полиса</td>
              <td colSpan="2">{paymentData.paymentNumber}</td>
            </tr>
            <tr>
              <td>Цена</td>
              <td>
                {' '}
                <span className={styles.manat}>
                  <img src={manat} alt="manat" />
                </span>
              </td>
              <td>{paymentData.total}</td>
            </tr>
            <tr>
              <td>Оплачено</td>
              <td>
                {' '}
                <span className={styles.manat}>
                  <img src={manat} alt="manat" />
                </span>
              </td>
              <td>{paymentData.payedAmount}</td>
            </tr>
            <tr>
              <td>Долг</td>
              <td>
                {' '}
                <span className={styles.manat}>
                  <img src={manat} alt="manat" />
                </span>
              </td>
              <td>{paymentData.debt}</td>
            </tr>
            <tr>
              <td>Дата следующей оплаты</td>

              <td colSpan="2">{paymentData.nextPaymentDate}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.linkBlock}>
        <img src={phoneNumber} alt="qr code" />
        <img src={qrCode} alt="qr code" />
        <div className={styles.social}>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.anotap.kasko&hl=en"
          >
            <img src={googlePlay} alt="google play" />
          </a>
          <a target="_blank" href="https://2222.az/">
            <img src={shortLogo} alt="logo" />
          </a>
          <a target="_blank" href="https://apps.apple.com/az/app/kasko-butik/id1435816463">
            <img src={appStore} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

PaymentSuccess.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(PaymentSuccess);
