import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FacebookShareButton } from 'react-share';

import minusIcon from 'assets/images/minus_icon.svg';
import shareIcon from 'assets/images/share_icon.svg';
import cx from 'classnames';
import { slide1, slide2, slide3, slide4 } from './Slides';
import useOutsideAlerter from 'helpers/clickOutside';

import styles from './LimitedSize.module.scss';

const mobileCards = [
  'images/kfit/mobile/campaign1.png',
  'images/kfit/mobile/campaign2.png',
  'images/kfit/mobile/campaign3.png',
  'images/kfit/mobile/campaign4.png'
];

const tabletCards = [
  'images/kfit/tablet/campaign1.png',
  'images/kfit/tablet/campaign2.png',
  'images/kfit/tablet/campaign3.png',
  'images/kfit/tablet/campaign4.png'
];

const desktopCards = [
  'images/kfit/desktop/campaign2.png',
  'images/kfit/desktop/campaign3.png',
  'images/kfit/desktop/campaign4.png',
  'images/kfit/desktop/campaign5.png'
];

const SliderContent = (props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { setIsViewSlider, content, screen } = props;

  const contentRef = useRef(null);
  const swiperControl = useRef(null);

  const handleOutside = () => {
    setIsViewSlider(false);
  }

  const handleSetSlide = index => swiperControl.current && swiperControl.current.swiper.slideTo(index);

  const slides = screen === 'mobile'
    ? mobileCards
    : screen === 'tablet'
      ? tabletCards
      : desktopCards;

  useOutsideAlerter(contentRef, handleOutside);

  return (
    <div ref={contentRef} className={styles.shadowWrapperr}>
      <button className={styles.minusBtn} onClick={() => setIsViewSlider(false)} type="button">
        <img src={minusIcon} alt="" />
      </button>
      <FacebookShareButton url={`${window.location}${slides[activeSlide]}`} quote={content.kFit.stockQuote} className={styles.shareBtn}>
        <a><img src={shareIcon} alt="share" /></a>
      </FacebookShareButton>
      <Swiper
        ref={swiperControl}
        className={styles.slider}
        onSlideChange={swiperData => setActiveSlide(swiperData.activeIndex)}
      >
        <div className={styles.pointBlock}>
          {Object.keys(slides).map((number) => (
            <div
              key={number}
              className={cx(styles.point, { [styles.active]: Number(number) === activeSlide })}
              onClick={() => handleSetSlide(number)}
            />
          ))}
        </div>
        {
          slides.map(slide => (
            <SwiperSlide key={slide}>
              <img src={slide} alt="slide" />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

SliderContent.propTypes = {
  setIsViewSlider: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
};

export default SliderContent;
