import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import styles from "./InsuranceHeader.module.scss";

SwiperCore.use([Navigation, Pagination]);

const InsuranceHeaderMobileSlider = (props) => {
  const { content } = props;

  const innerText = (value) => ({ __html: value });

  return (
    <Swiper
      className={styles.titleWrapper}
      pagination={{ clickable: true }}
    >
      <SwiperSlide className={styles.sliderMobile}>
        <h2
          className={styles.leftTitle}
          dangerouslySetInnerHTML={innerText(content.common.step1Mob)}
        />
      </SwiperSlide>
      <SwiperSlide className={styles.sliderMobile}>
        <h2
          className={styles.rightTitle}
          dangerouslySetInnerHTML={innerText(content.common.step2Mob)}
        />
      </SwiperSlide>
    </Swiper>
  );
};

InsuranceHeaderMobileSlider.propTypes = {
  content: PropTypes.shape({
    common: PropTypes.shape({
      step1: PropTypes.string,
      step2: PropTypes.string,
    }),
  }).isRequired,
};

export default InsuranceHeaderMobileSlider;
