import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import hamidantezVertical from 'assets/images/hamidantez-vertical.svg';
import hamidantezTag from 'assets/images/hamidantez-tag.svg';

import { ICONS_HAMIDANTEZ_MOBILE } from './consts';

import styles from './InsuranceFooter.module.scss';


SwiperCore.use([Pagination]);

const InsuranceFooterMobile = ({ content, currentLang, screen }) => {
  const { hamidantez } = content;
  const innerText = (value) => ({ __html: value });

  return (
    <Fragment>
      <div id="header-anchor" className={styles.wrapper}>
        <Swiper
          pagination
          style={{ paddingBottom: '20px' }}
        >
          <SwiperSlide style={{ overflow: 'hidden' }}>
            <div className={styles.wrapperIntroSlide}>
              <div className={styles.wrapperBackground} />
              <div className={styles.wrapperInner}>
                <div className={styles.container}>
                  <img src={hamidantezTag} alt="" />
                  <h2>{hamidantez.title}</h2>
                  <p>{hamidantez.description}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.wrapperListSlide}>
              <div className={styles.wrapperBackground} />
              <img src={hamidantezVertical} className={styles.tabletBackground} alt="" />
              <div className={styles.wrapperInner}>
                <div className={styles.container}>
                  <div className={styles.iconBlock}>
                    {hamidantez.image &&
                    Object.keys(hamidantez.image).map((i, index) => {
                      const updatedString = hamidantez.image[i].nameMob;
                      return (
                        <div key={i} className={styles.icon}>
                          <div className={styles.imgContainer}>
                            <img src={ICONS_HAMIDANTEZ_MOBILE[index]} alt="crash"/>
                          </div>
                          <span dangerouslySetInnerHTML={innerText(updatedString)}/>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </Fragment>
  );
};

InsuranceFooterMobile.propTypes = {
  content: PropTypes.shape({
    hamidantez: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.array,
    }),
  }).isRequired,
  currentLang: PropTypes.string.isRequired,
  screen: PropTypes.string.isRequired,
};

export default InsuranceFooterMobile;
