import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { gsap } from 'gsap';

import styles from './AnimatedSlider.module.scss';

const AnimatedSliderBlock = props => {
  const {
    screen,
    isViewSlider,
    setIsViewSlider,
  } = props;

  const { MorphSVGPlugin, TimelineMax } = window;

  gsap.registerPlugin(MorphSVGPlugin);

  const [tl, setTl] = useState(new TimelineMax());
  const wrapper = useRef(null);
  const element = useRef(null);
  const step0 = useRef(null);
  const step1 = useRef(null);
  const step2 = useRef(null);
  const step3 = useRef(null);
  const open = useRef(null);
  const svgMain = useRef(null);

  useEffect(() => {
    if (isViewSlider) {
      svgMain.current.style = "display: block";
      wrapper.current.classList.add("active");

      tl
        .to(element.current, .25, {
          morphSVG: step1.current
        })
        .to(element.current, .25, {
          morphSVG: step2.current
        })
        .to(element.current, .25, {
          morphSVG: step3.current,
        }, "-=.15")
        .to(element.current, .25, {
          y: "0",
          onComplete: function(){
            // open.current.classList.add("active");
          }
        }, "-=.15")
    } else {
      wrapper.current && wrapper.current.classList.remove("active");

      tl
        .to(element.current, .25, {
          morphSVG: step2.current,
        })
        .to(element.current, .25, {
          morphSVG: step1.current,
        })
        .to(element.current, .25, {
          morphSVG: step0.current
        }, "-=.15")
        .to(element.current, .25, {
          y: "0",
          onComplete: function(){
            wrapper.current && wrapper.current.classList.remove("active");
            (svgMain.current || {}).style = "display: none";
          }
        }, "-=.3")
    }
  }, [isViewSlider]);

  switch (screen) {
    case 'mobile': return (
      <svg
        className={cx(styles.sliderBlock, styles.sliderBlockHidden)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 332 720"
        ref={svgMain}
      >
        <g id="Page-1" fill="none" fillRule="evenodd">
          <g ref={wrapper} id="modal-with-genie-effect">
            <path ref={step0} id="step-0" d="M307.56,716.36V720h10.18v-3.3Z" opacity=".504"/>
            <path ref={step1} id="step-1" d="M249.93,209.13S283.84,285.87,295,453.79C302.07,612.4,307.56,720,307.56,720h10.18S307.5,552.38,307.56,416.16c0-73.2-1.45-207-1.45-207Z" opacity=".5"/>
            <path ref={step2} id="step-2" d="M58.23,57.76S153,163.21,202,293.17C246.31,410.58,307.56,720,307.56,720h10.18s-6.25-232.28-6.19-360C311.92,234.64,332,57.76,332,57.76Z" opacity=".5"/>
            <path ref={step3} id="step-3" d="M0,0S0,238.26,0,360C.1,611.4,0,720,0,720H332s0-191.74,0-360V0Z"/>
            <path ref={element} id="element" fill="#232D46" d="M307.56,716.36V720h10.18v-3.3Z"/>
          </g>
        </g>
      </svg>
    );

    case 'tablet': return (
      <svg
        className={cx(styles.sliderBlock, styles.sliderBlockHidden)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 914 1210"
        ref={svgMain}
      >
        <g id="Page-1" fill="none" fillRule="evenodd">
          <g ref={wrapper} id="modal-with-genie-effect">
            <path ref={step0} id="step-0" d="M793.9,1203.87V1210h28v-5.55Z" opacity=".504"/>
            <path ref={step1} id="step-1" d="M688,351.45S770.45,478.82,791.33,765c19.46,266.55,2.57,445,2.57,445h28s2.39-278.08,2.57-507c.1-123,18.17-351.55,18.17-351.55Z" opacity=".5"/>
            <path ref={step2} id="step-2" d="M160.3,97.08S376.67,280.33,511.67,498.74C633.64,696.05,793.9,1210,793.9,1210h28s35.57-390.36,35.74-605c1-210.67,56.27-507.92,56.27-507.92Z" opacity=".5"/>
            <path ref={step3} id="step-3" d="M0,0S-.06,400.4,0,605c.23,422.5,0,605,0,605H913.94s0-322.23,0-605V0Z"/>
            <path ref={element} id="element" fill="#232D46" d="M793.9,1203.87V1210h28v-5.55Z"/>
          </g>
        </g>
      </svg>
    );

    default: return (
      <svg
        className={cx(styles.sliderBlock, styles.sliderBlockHidden)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1152 482"
        ref={svgMain}
      >
        <g id="Page-1" fill="none" fillRule="evenodd">
          <g ref={wrapper} id="modal-with-genie-effect">
            <path ref={step0} id="step-0" d="M1116.73,479.56V482h35.33s0-.42,0-1v-1.21Z" opacity=".504"/>
            <path ref={step1} id="step-1" d="M1000.89,140s89.5,51.5,115.83,165.5c24.53,106.18,0,176.5,0,176.5h35.33s-.23-149.81,0-241c.12-49,0-101,0-101Z" opacity=".5"/>
            <path ref={step2} id="step-2" d="M202.06,38.67s474.83,73,645,160C1000.81,277.27,1116.73,482,1116.73,482h35.33s-.21-155.5,0-241c.17-68.33,0-202.33,0-202.33Z" opacity=".5"/>
            <path ref={step3} id="step-3" d="M.06,0s-.14,159.5,0,241c.29,168.3,0,241,0,241h1152s-.06-128.36,0-241c0-89.67,0-241,0-241Z"/>
            <path ref={element} id="element" fill="#232D46" d="M1116.73,479.56V482h35.33s0-.42,0-1v-1.21Z"/>
          </g>
        </g>
      </svg>
    );
  }
};

AnimatedSliderBlock.propTypes = {
  isViewSlider: PropTypes.func.isRequired,
  setIsViewSlider: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
};

export default AnimatedSliderBlock;
