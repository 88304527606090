import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper-coverflow-improve/react';
import SwiperCore, { EffectCoverflow } from 'swiper-coverflow-improve';

import kMatik from 'assets/images/k-matik.svg';
import flipSound from 'assets/sounds/flip-card.wav';
import vibraionSound from 'assets/sounds/vibration.wav';
import kMatikBg from 'assets/svg/kMatik-mobile-bg.svg';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './FullKaskoSizeMobile.module.scss';
import CardKFit from "./CardKFit";

const sortCard = ['k500', 'k1000', 'k2000', 'k2000Plus'];
const soundFlip = new Audio(flipSound);
const soundVibration = new Audio(vibraionSound);

SwiperCore.use([EffectCoverflow]);

const FullKaskoSizeMobile = ({ content, isMobile, screen }) => {
  const [isViewSlider, setIsViewSlider] = useState(false);
  const [activeDesc, setActiveDesc] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const wrapperSwiper = useRef(null);

  const switchCard = () => {
    setActiveDesc((prevActiveDesc) => !prevActiveDesc);
    soundVibration.play();
    setTimeout(() => soundFlip.play(), 500);
  };
  const innerText = (value) => ({ __html: value });

  const handleOk = () => {
    wrapperSwiper.current.swiper.slideNext();
  };

  function onSlideChange() {
    const swiper = this;
    setActiveSlide(swiper.activeIndex);
  }

  return (
    <Swiper ref={wrapperSwiper} style={{ width: '100%' }} resistanceRatio={0}>
      <SwiperSlide>
        <div className={cx(styles.wrapper)}>
          <div className={cx(styles.inner)}>
            <div className={styles.contentWrapper}>
              <div
                className={cx(styles.main, styles.mainDescription, {
                  [styles.slideText]: isViewSlider,
                  [styles.backSlideText]: !isViewSlider,
                })}
              >
                <div className={cx(styles.logo, styles.logoDescription)}>
                  <h2 className={styles.sloganDescription}>{content.kMatik.slogan}</h2>
                  <img src={kMatik} alt="kMatik" />
                </div>
                <div className={styles.textBlock}>
                  <h1 dangerouslySetInnerHTML={innerText(content.kMatik.kMatikTitleMob)}></h1>
                  <p dangerouslySetInnerHTML={innerText(content.kMatik.kFitDescription)}></p>
                </div>
              </div>
              <p
                className={styles.descriptionText}
                dangerouslySetInnerHTML={innerText(content.kMatik.kMatikDescription)}
              />
            </div>
            <div
              className={cx(styles.btbBlock, {
                [styles.slideText]: isViewSlider,
                [styles.backSlideText]: !isViewSlider,
              })}
            >
              <div className={styles.btnWrapper}>
                <button type="button" className={styles.btn} onClick={handleOk}>
                  OK
                </button>
              </div>
            </div>
          </div>
          <img className={styles.mobileBg} src={kMatikBg} alt="kFit" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={`wrapperSlide ${cx(styles.wrapper, styles.sliderWrapper)}`}>
          <div className={cx(styles.inner)}>
            <div
              className={cx(styles.main, {
                [styles.slideText]: isViewSlider,
                [styles.backSlideText]: !isViewSlider,
              })}
            >
              <div className={styles.logo}>
                <h2>{content.kMatik.slogan}</h2>
                <img src={kMatik} alt="kMatik" />
              </div>
              <div className={styles.textBlock}>
                <h1 dangerouslySetInnerHTML={innerText(content.kMatik.kMatikTitleMob)}></h1>
                <p dangerouslySetInnerHTML={innerText(content.kFit.kFitDescription)}></p>
              </div>
            </div>
            <Swiper
              className={styles.sizeBlock}
              effect="coverflow"
              nested
              pagination
              watchSlidesProgress
              slidesPerView={1}
              spaceBetween={screen === 'mobile' ? -240 : -600}
              resistanceRatio={0}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: screen === 'mobile' ? 1200 : 1400,
                modifier: 1,
                slideShadows: false,
              }}
              onSlideChange={onSlideChange}
            >
              {sortCard.map((key) => (
                <SwiperSlide key={key}>
                  <CardKFit
                    key={key}
                    images={[content.kMatik.frontFaces[`${key}`], content.kMatik.backFaces[`${key}`]]}
                    activeDesc={activeDesc}
                    isMobile={isMobile}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={styles.wrapperPagination}>
              <div className={cx(styles.customPagination, "swiper-pagination swiper-pagination-bullets")}>
                {/* PHANTOM POINT */}
                <span className="swiper-pagination-bullet" />
                {sortCard.map((key, index) => (
                  <span key={key} className={cx("swiper-pagination-bullet", { "swiper-pagination-bullet-active": activeSlide === index })}></span>
                ))}
              </div>
            </div>
            <div
              className={cx(styles.btbBlock, {
                [styles.slideText]: isViewSlider,
                [styles.backSlideText]: !isViewSlider,
              })}
            >
              <div className={styles.btnWrapper}>
                <button type="button" className={styles.btn} onClick={switchCard}>
                  {content.common.compareButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

FullKaskoSizeMobile.propTypes = {
  content: PropTypes.shape({
    kFit: PropTypes.shape({
      slogan: PropTypes.string,
      kFitDescription: PropTypes.string,
      kFitTitle: PropTypes.string,
    }),
    common: PropTypes.shape({
      compareButton: PropTypes.string,
    }),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  screen: PropTypes.string.isRequired,
};

export default FullKaskoSizeMobile;
