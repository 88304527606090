import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as FacebookIcon } from 'assets/images/survey/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'assets/images/survey/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/survey/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/survey/youtube.svg';
import { ReactComponent as HamidantezIcon } from 'assets/images/survey/hamidantez.svg';

import styles from './SurveySocial.module.scss';

const SurveySocial = props => {
  return (
    <div className={styles.container}>
      <div className={styles.socialNetworks}>
        <a href="https://www.facebook.com/kaskobutik/">
          <FacebookIcon />
        </a>
        <a href="https://www.linkedin.com/company/kaskobutik/">
          <LinkedInIcon />
        </a>
        <a href="https://www.instagram.com/kasko_butik/?hl=en">
          <InstagramIcon />
        </a>
        <a href="https://www.youtube.com/channel/UCe_pP1tuZmGa1EJ4gSvMoYg">
          <YoutubeIcon />
        </a>
      </div>
      <a href="#" className={styles.hamidantez}>
        <HamidantezIcon />
      </a>
    </div>
  );
};

SurveySocial.propTypes = {

};

export default SurveySocial;
