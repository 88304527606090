import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './InsuranceFooter.module.scss';

import hamidantezTag from '../../assets/images/hamidantez-tag.svg';
import { ICONS_HAMIDANTEZ } from './consts';

const InsuranceFooter = ({ content, currentLang }) => {
  const { hamidantez } = content;
  const innerText = (value) => ({ __html: value });

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.wrapperBackground} />
        <div className={styles.wrapperInner}>
          <div className={styles.container}>
            <img src={hamidantezTag} alt="" />
            <h2>{hamidantez.title}</h2>
            <p>{hamidantez.description}</p>

            <div className={styles.iconBlock}>
              {hamidantez.image &&
              Object.keys(hamidantez.image).map((i, index) => (
                <div key={i} className={styles.icon}>
                  <div className={styles.imgContainer}>
                    <img src={ICONS_HAMIDANTEZ[index]} alt="crash" />
                  </div>
                  <span dangerouslySetInnerHTML={innerText(hamidantez.image[i].name)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

InsuranceFooter.propTypes = {
  content: PropTypes.shape({
    hamidantez: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.array,
    }),
  }).isRequired,
  currentLang: PropTypes.string.isRequired,
};

export default InsuranceFooter;
