import React from 'react';
import cx from 'classnames';

import stylesDefault from './FullKaskoSize.module.scss';
import stylesMob from './FullKaskoSizeMobile.module.scss';

const CardKFit = ({ images, activeDesc, isMobile }) => {
  const convertImg = images.map((i) => i.replace('.png', '@3x.png'));

  const styles = isMobile ? stylesMob : stylesDefault;

  return (
    <div
      className={cx(styles.card, {
        [styles.flip]: activeDesc,
        [styles.backFlip]: !activeDesc && activeDesc !== null,
        [styles.shake]: activeDesc,
        [styles.shakeBack]: !activeDesc && activeDesc !== null,
      })}
    >
      <div className={styles.front}>
        <img src={convertImg[0]} alt="tag" className={cx(styles.cardSize)} />
      </div>
      <div className={styles.back}>
        <img src={convertImg[1]} alt="tag" className={cx(styles.cardSize)} />
      </div>
    </div>
  );
};

export default CardKFit;
