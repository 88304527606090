import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './LimitedSize.module.scss';

const CardKMatic = ({ images, activeDesc }) => {
  const convertImg = images.map((i) => i.replace('.png', '@3x.png'));

  return (
    <div
      className={cx({
        [styles.cardWrapper]: true,
        [styles.flip]: activeDesc,
        [styles.backFlip]: !activeDesc && activeDesc !== null,
      })}
    >
      <div className={styles.front}>
        <img src={convertImg[0]} alt="tag" className={cx(styles.cardSize)} />
      </div>
      <div className={styles.back}>
        <img src={convertImg[1]} alt="tag" className={cx(styles.cardSize)} />
      </div>
    </div>
  );
};

CardKMatic.propTypes = {
  images: PropTypes.array.isRequired,
  activeDesc: PropTypes.any.isRequired,
};

export default CardKMatic;
