import isString from 'lodash/isString';
import isObjectLike from 'lodash/isObjectLike';
import isArray from 'lodash/isArray';
import { stringify } from 'query-string';
// import fetch from 'node-fetch';
import base64 from 'base-64';


// import history from '../history';

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

function configureHeaders(body) {
  const headers = new Headers();

  const isFormData = body instanceof FormData;
  if (!isFormData) {
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', 'Basic ' + base64.encode(process.env.REACT_APP_HTTP_AUTH_USER + ":" + process.env.REACT_APP_HTTP_AUTH_PASS));
  }
  return headers;
}

function configureBody(body) {
  if (!body) {
    return undefined;
  }
  if (body instanceof FormData) {
    return body;
  }
  return JSON.stringify(body);
}

export function getRequestUrl(path = '', params) {
  const query = params ? `?${stringify(params)}` : '';
  return process.env.REACT_APP_API_URL + path + query;
}

function configureRequest({ method, body }) {
  return {
    headers: configureHeaders(body),
    method,
    mode: 'cors',
    body: configureBody(body),
    timeout: 300000,
  };
}

function getFilenameFromContentDisposition(contentDispositionHeader) {
  const filenameRegex = /Filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDispositionHeader);
  return matches[1].replace(/['"]/g, '') || 'file';
}

async function getContent(response) {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.startsWith('application/json')) {
    try {
      return await response.clone().json();
    } catch (error) {
      /** empty json body will throw "SyntaxError: Unexpected end of input" */
      if (error instanceof SyntaxError) {
        return response.clone().text();
      }
    }
  }

  const contentDisposition = response.headers.get('content-disposition');
  if (contentDisposition) {
    const blob = await response.blob();
    const name = getFilenameFromContentDisposition(contentDisposition);
    return { blob, name };
  }

  return response.text();
}

async function handleErrors(response) {
  if (response.ok) {
    return getContent(response);
  }

  if (response.status === 401) {
    // resetToken();
    // history.push('/login');
    throw new Error(response.statusText);
  } else {
    const body = await response.json();
    let errorMessage;
    if (isObjectLike(body) && body.error) {
      errorMessage = body.error;
    } else if (isString(body)) {
      errorMessage = body;
    } else if (isArray(body)) {
      errorMessage = body[0].error;
    } else {
      errorMessage = response.statusText;
    }
    throw new Error(errorMessage);
  }
}

/**
 * @param {string} method - HTTP method
 * @param {string} path - Relative request path
 * @param {Object} [body] - Request body
 * @param {Object} [params] - Query params
 * @return {Promise<Response | void>}
 */
async function makeRequest(method, { path, body, params }) {
  const url = getRequestUrl(path, params);
  const request = configureRequest({ method, body });
  const response = await fetch(url, request);
  return handleErrors(response);
}

export const get = makeRequest.bind(null, HttpMethods.GET);
export const post = makeRequest.bind(null, HttpMethods.POST);
export const put = makeRequest.bind(null, HttpMethods.PUT);
export const del = makeRequest.bind(null, HttpMethods.DELETE);

export default {
  get, post, put, del,
};
