import React from 'react';
import PropTypes from 'prop-types';

import logo from 'assets/svg/logo.svg';
import QRcode from 'assets/svg/QRcode.svg';
import gear from 'assets/svg/gear.svg';
import mobile from 'assets/svg/mobile.svg';
import number from 'assets/svg/number.svg';
import googlePlay from 'assets/svg/google-play.svg';
import appleStore from 'assets/svg/apple-store.svg';
import shortLogo from 'assets/svg/short-logo.svg';
import socialBg from 'assets/svg/social-bg.svg';
import facebook from 'assets/svg/facebook.svg';
import linkedIn from 'assets/svg/linkedIn.svg';
import youtube from 'assets/svg/youtube.svg';
import instagram from 'assets/svg/instagram.svg';

import './styles.scss'

const MaintenancePage = props => {
  return (
    <div className="container">
      <div id="logo">
        <img src={logo} alt="logo"/>
      </div>
      <section id="main-section">
        <div id="text-block">
          <div id="gear-block">
            <img src={gear} alt="gear"/>
          </div>
          <h2>
            <span className="bold-text">Sayt yenilənir</span> <br/>
            Ən maraqlısı hələ
            qabaqdadır
          </h2>
        </div>
        <div id="mobile">
          <img src={mobile} alt="mobile"/>
          <img src={number} alt="number" id="short-number"/>
          <div id="qrcode">
            <img src={QRcode} alt="QRcode"/>
          </div>
        </div>
        <div id="link-block">
          <div id="store-block">
            <a href="https://play.google.com/store/apps/details?id=com.anotap.kasko&hl=en">
              <img src={googlePlay} alt="google-play" id="g-play"/>
            </a>
            <a href="https://apps.apple.com/az/app/kasko-butik/id1435816463">
              <img src={appleStore} alt="apple-store" id="appstore"/>
            </a>
          </div>
          <div id="short-logo">
            <img src={shortLogo} alt="short-logo"/>
          </div>
        </div>
      </section>
      <footer>
        <a href="https://www.facebook.com/kaskobutik/">
          <img src={facebook} alt="facebook" className="icon"/>
        </a>
        <a href="https://www.linkedin.com/company/kaskobutik/">
          <img src={linkedIn} alt="linkedin" className="icon"/>
        </a>
        <a href="https://www.instagram.com/kasko_butik/?hl=en">
          <img src={instagram} alt="instagram" className="icon"/>
        </a>
        <a href="https://www.youtube.com/channel/UCe_pP1tuZmGa1EJ4gSvMoYg">
          <img src={youtube} alt="youtube" className="icon"/>
        </a>

        <img src={socialBg} alt="social-bg" id="social-bg" />
      </footer>
    </div>
  );
};

MaintenancePage.propTypes = {

};

export default MaintenancePage;
