import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FacebookShareButton } from "react-share";

import logo from 'assets/images/logo.svg';
import styles from './Questionnaire.module.scss';
import shareIcon from '../../assets/images/share_icon.svg';

let headerDisplay = true;

const socialAds = {
  EN: {
    desktop: 'images/social/site/en/social.png',
    tablet: 'images/social/tablet/en/social.png',
    mobile: 'images/social/mobile/en/social.png',
  },
  RU: {
    desktop: 'images/social/site/ru/social.png',
    tablet: 'images/social/tablet/ru/social.png',
    mobile: 'images/social/mobile/ru/social.png',
  },
  AZ: {
    desktop: 'images/social/site/az/social.png',
    tablet: 'images/social/tablet/az/social.png',
    mobile: 'images/social/mobile/az/social.png',
  },
}

const Questionnaire = ({ content, isMobile, screen, currentLang }) => {
  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onScroll = () => {
    const header = document.querySelector('#header');
    const headerAnchor = document.querySelector('#header-anchor');

    if (!header) return false;

    const headerTop = header.getBoundingClientRect().top;
    const headerHeight = header.getBoundingClientRect().height;
    const headerAnchorTop = headerAnchor.getBoundingClientRect().top;

    const headerShouldBeSkipped = headerDisplay
      ? (headerTop) >= headerAnchorTop - headerHeight
      : (headerTop + headerHeight) >= headerAnchorTop - headerHeight;

    if (headerShouldBeSkipped && headerDisplay) {
      header.style.top = `-${headerHeight}px`;
      headerDisplay = false;
    }
    if (!headerShouldBeSkipped && !headerDisplay) {
      header.style.top = '0px';
      headerDisplay = true;
    }
    return true;
  };

  const banner = `${window.location}${socialAds[currentLang][screen]}`;

  return (
    <div className={styles.wrapper}>
      <header>
        <img src={logo} alt="logo" className={styles.logo} />
        <span>{content.social.title}</span>
      </header>
      <div className={styles.banner}>
        <FacebookShareButton url={banner} quote={content.social.shareQuote}>
          <a><img src={shareIcon} alt="share" /></a>
        </FacebookShareButton>
        <img src={banner} alt="banner"/>
      </div>
    </div>
  )
};

Questionnaire.propTypes = {
  content: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  screen: PropTypes.string.isRequired,
  currentLang: PropTypes.string.isRequired,
}

export default Questionnaire;
