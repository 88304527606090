import { get, post, put, del } from 'services/api';

export const getContent = (lang) => get({ path: `/api/content/v2/${lang}` });

export const getPaymentByPolicyNumber = (policyNumber) => get({ path: `/api/payment/${policyNumber}` });

export const payPashaBank = (params) => get({ path: '/api/pasha-bank/client-url', params });

export const isPaymentSuccess = (body) => post({ path: '/api/pasha-bank/v2/result', body });
export const paymentAudit = (body) => post({ path: '/api/pasha-bank/payment/audit', body });

export const isGoldenPaymentSuccess = (body) => post({ path: '/api/golden-pay/v2/result', body });
export const goldenPaymentAudit = (body) => post({ path: '/api/golden-pay/payment/audit', body });
export const goldenMakePay = (body) => post({ path: '/api/golden-pay/make/payment', body });

// SURVEY
export const getSurvey = (id) => get({ path: `/api/claims/questionnaire/${id}` });
export const postSurvey = (id, type) => post({ path: `/api/claims/questionnaire/${id}?answer=${type}` });
