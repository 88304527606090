import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as requests from 'requests';
import getCurrentLang from 'helpers/getCurrentLang';

import Loader from 'components/Loader';

import logo from 'assets/images/logo.svg';
import { ReactComponent as Like } from 'assets/images/survey-like.svg';
import { ReactComponent as Dislike } from 'assets/images/survey-dislike.svg';
import appleStore from 'assets/images/badge-apple.svg';
import googlePlay from 'assets/images/badge-google.svg';
import appGallery from 'assets/images/badge-gallery.svg';

import SurveySocial from './SurveySocial';
import SurveyCompleted from './SurveyCompleted';

import styles from './SurveyPage.module.scss';
import useWindowDimensions from "../../helpers/useWindowDimensions";

const translate = {
  title: 'Выбери свой размер!',
  payment: 'Оплата прошла успешно!',
};

const SurveyPage = props => {
  const { location, history } = props;

  const [status, setStatus] = useState(null);
  const [loader, setLoader] = useState(true);
  const [translates, setTranslates] = useState(null);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    getSurveyInit();
    getTranslates();
  }, []);

  const innerText = (value) => ({ __html: value });

  const getSurvey = () => {
    const idParam = new URLSearchParams(location.search).get('claimUid');

    requests.getSurvey(idParam)
      .then(data => data.answer && setStatus(data.answer))
      .finally(() => setLoader(false));
  };

  const getSurveyInit = () => {
    const idParam = new URLSearchParams(location.search).get('claimUid');

    requests.getSurvey(idParam)
      .then((data) => {
        if (data.answer) {
          const statusValue = data.answer === 'LIKE' ? 'ANSWERED_LIKE' : 'ANSWERED_DISLIKE';
          setStatus(statusValue);
        }
      })
      .finally(() => setLoader(false));
  }

  const getTranslates = () => {
    const lang = getCurrentLang(location);

    requests.getContent(lang)
      .then(data => setTranslates(data));
  };

  const handleChoice = async (type) => {
    const idParam = new URLSearchParams(location.search).get('claimUid');

    setLoader(true);
    await requests.postSurvey(idParam, type);
    await getSurvey();
  }

  if (!translates) return <Loader />;
  if (status) return <SurveyCompleted status={status} translates={translates} />

  return (
    <div className={styles.container} style={{ height }}>
      {loader && <Loader />}
      <div className={styles.main}>
        <img className={styles.logo} src={logo} alt="logo" />
        <h3 dangerouslySetInnerHTML={innerText(translates.common.chooseYourSize)} />
        <h1 className={styles.mainTitle} dangerouslySetInnerHTML={innerText(translates.survey.askTitle)} />
      </div>
      <div className={styles.userActions}>
        <button onClick={() => handleChoice('LIKE')}>
          <Like />
        </button>
        <button onClick={() => handleChoice('DISLIKE')}>
          <Dislike />
        </button>
      </div>
      <div className={styles.appLinks}>
        <a href="https://apps.apple.com/az/app/kasko-butik/id1435816463">
          <img src={appleStore} alt="Apple Store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.anotap.kasko&hl=en">
          <img src={googlePlay} alt="Google Play" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.anotap.kasko&hl=en">
          <img src={appGallery} alt="App Gallery" />
        </a>
      </div>
      <div className={styles.surveySocialWrapper}>
        <SurveySocial />
      </div>
    </div>
  );
};

SurveyPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default SurveyPage;
